'use client';

import dynamic from 'next/dynamic';

import { BrowserAuth } from '@/lib/auth/browser';
import { Button } from '@/lib/io-kit/Button';

const NextAppErrorBoundary = dynamic(() =>
  import('@/components/NextAppErrorBoundary').then((mod) => mod.NextAppErrorBoundary),
);

export default function IoGlobalErrorBoundary({ error }: { error: Error; reset: () => void }) {
  return (
    <html>
      <body>
        <NextAppErrorBoundary
          error={error}
          actions={
            <>
              <Button as="button" variant="primary" onClick={window.location.reload}>
                Try Again
              </Button>

              <Button as="button" variant="dark" onClick={BrowserAuth.Session.signOut}>
                Retry Login
              </Button>
            </>
          }
        />
      </body>
    </html>
  );
}
